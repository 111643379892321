<template>
  <div class="edit_box">
    <div class="mrb20"> <el-button type="primary" @click="viewProblem()">添加自定义问答</el-button></div>
    <div class="flex mrb40">
      <el-dropdown class="filter-select" @command="handleCompanyChange" trigger="click">
        <el-button>
          {{company}}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item,index) in companyOptions" :key="index" :command="item">{{item.label}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="mrr20"><el-input v-model="findForm.name" placeholder="请输入想要查询名称" clearable></el-input></div>
      <el-button type="primary" @click="getList">查询</el-button>
    </div>

    <template>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="ID">
        </el-table-column>
        <el-table-column prop="date" label="卖家问题">
          <template slot-scope="scope">
            <div>{{ scope.row.name }}</div>
            <el-button type="text" @click="viewProblem(scope.row)">查看卖家问题</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="chat_count" label="月资讯量" width="150">
        </el-table-column>
        <el-table-column prop="reply_content" label="回复内容">
          <template slot-scope="scope">
            <div>{{ scope.row.reply_content }}</div>
            <el-button v-if="scope.row.reply_content == ''" type="text" @click="replyClick(scope.row)">添加回复内容</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="reply_image" label="回复图片" width="120" align="center">
          <template slot-scope="scope">
            <el-image :preview-src-list="[scope.row.reply_image]" style="width: 100px; height: 100px" :src="scope.row.reply_image" fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="" width="150">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline size20 mrr40" @click="handleEdit(scope.row)"></i>
            <i class="el-icon-delete size20" @click="handleremove(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="自动回复" width="150">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" @change="statusClick(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="mrt20 flex justify-end ">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 50]" :page-size="pageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageInfo.count">
        </el-pagination>
      </div>
    </template>

    <!-- 查看卖家问题 -->
    <el-dialog title="热门问题问法管理" :visible.sync="issueVisible" width="50%">
      <el-form ref="issueForm" :model="issueForm" label-width="80px">
        <el-form-item label="卖家问题">
          <el-input type="text" placeholder="请输入内容" v-model="issueForm.name" maxlength="20" show-word-limit style="width: 450px;">
          </el-input>
        </el-form-item>
        <!-- :label="index <=1?'卖家问法' :''" -->
        <el-form-item label="卖家问法">
          <div class="mrb20" v-for="(item,index) in issueForm.keyword" :key="index">
            <el-input type="text" placeholder="请输入内容" v-model="item.keyword" maxlength="20" show-word-limit style="width: 450px;margin-right: 10px;">
            </el-input>
            <el-button type="danger" icon="el-icon-delete" circle @click="deletecustom(index)"></el-button>
          </div>
        </el-form-item>
        <el-button type="primary" @click="addSetting()">添加问法</el-button>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="issueVisible = false">取 消</el-button>
        <el-button type="primary" @click="addIssue()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="添加回复内容" :visible.sync="replyVisible" width="50%">
      <el-form ref="replyForm" :model="replyForm" label-width="80px">
        <el-form-item label="回复文字">
          <el-input type="textarea" v-model="replyForm.reply_content" placeholder="请输入回复内容"></el-input>
        </el-form-item>
        <el-form-item label="回复图片">
          <file-pic :file='replyForm.reply_image' @input='replyForm.reply_image = $event'></file-pic>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="replyVisible = false">取 消</el-button>
        <el-button type="primary" @click="addReply()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import filePic from "@/components/common/filePic";
export default {
  components: { filePic },
  props: {},
  data() {
    return {
      issueVisible: false,
      replyVisible: false,
      input: "",
      pageInfo: {
        limit: 10,
        page: 1,
        count: 0,
        pageNum: 0,
      },
      company: "问题",
      findForm: {
        type: 1,
        name: "",
      },
      editID: "",
      editIdPng: "",
      replyForm: {
        reply_content: "",
        reply_image: "",
      },
      companyOptions: [
        {
          value: 1,
          label: "问题",
        },
        {
          value: 2,
          label: "回复",
        },
      ],
      issueForm: {
        id: "",
        name: "",
        keyword: [
          {
            keyword: "",
          },
        ],
      },

      tableData: [
        {
          name: "",
        },
      ],
    };
  },

  created() {
    this.getList(this.pageInfo);
  },
  mounted() {},
  computed: {},

  methods: {
    async getList(pageInfo) {
      const { data } = await this.$Api.help({ ...pageInfo, ...this.findForm });
      this.tableData = data.list;
      console.log("资讯列表", data);
      this.pageInfo.limit = Number(data.limit);
      this.pageInfo.page = Number(data.page);
      this.pageInfo.count = Number(data.count);
      this.pageInfo.pageNum = Number(data.pageNum);
      // Object.assign(this.pageInfo, data);
      // console.log(data, "成功的原著列表");
    },
    handleEdit(row) {
      this.editIdPng = row.id;
      this.replyVisible = true;
      this.replyForm = { ...row };
    },
    async addIssue() {
      let content = this.issueForm.keyword.map((item) => {
        return item.keyword;
      });
      const data = await this.$Api.helpEdit({
        id: this.issueForm.id,
        name: this.issueForm.name,
        content: content,
      });
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("提交成功");
      this.issueVisible = false;
      this.getList(this.pageInfo);
    },
    handleremove(row) {
      console.log("删除回复", row);
      this.$confirm("确认删除该回复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$Api.helPdelContent({
            id: row.id,
          });
          // console.log("删除成功", data);
          if (data.code != 200) {
            return this.$message({
              message: data.msg,
              type: "error",
            });
          }
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.getList(this.pageInfo);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: data.msg,
          });
        });
    },
    handleDelete(row) {
      this.$confirm("操作确认后，该问题将会被删除！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$Api.helpDel({ ids: [row] });
          // console.log("删除成功", data);
          if (data.code != 200) {
            return this.$message({
              message: data.msg,
              type: "error",
            });
          }
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.getList(this.pageInfo);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: data.msg,
          });
        });
    },
    async statusClick(val) {
      let parameter = {
        id: val.id,
        status: val.status,
      };
      const data = await this.$Api.helPeditStatus(parameter);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("修改成功");
      this.getList(this.pageInfo);
    },
    // 设置回复
    async addReply() {
      const data = await this.$Api.helpSetContent({
        id: this.editID,
        ...this.replyForm,
      });
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("设置回复成功");
      this.replyVisible = false;
      this.getList(this.pageInfo);
    },
    replyClick(row) {
      this.editID = row.id;
      this.replyForm = {
        reply_content: "",
        reply_image: "",
      };
      this.replyVisible = true;
    },
    handleCompanyChange(command) {
      this.company = command.label;
      this.findForm.type = command.value;
      this.getList();
      console.log("command", command);
    },
    viewProblem(row) {
      if (!row) {
        this.issueVisible = true;
        this.issueForm = {
          id: "",
          name: "",
          keyword: [
            {
              keyword: "",
            },
          ],
        };
      } else {
        this.issueForm.id = row.id;
        this.issueVisible = true;
        this.issueForm = { ...row, ...row.keyword };
      }
    },
    // addcustom() {
    //   console.log("添加");
    //   this.issueForm.text.push({ text1: "" });
    // },
    addSetting() {
      this.issueForm.keyword.push({ keyword: "" });
    },
    deletecustom(index) {
      this.issueForm.keyword.splice(index, 1);
    },
    handleSizeChange(val) {
      // console.log("val", val);
      this.pageInfo.limit = val;
      this.pageInfo.page = 1;
      this.getList(this.pageInfo);
    },
    handleCurrentChange(val) {
      // console.log("val", val);
      this.pageInfo.page = val;
      this.getList(this.pageInfo);
    },
  },
};
</script>
<style lang='scss' scoped>
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
</style>